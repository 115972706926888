<template>
  <div>
    <section id="card-text-alignment">
      <b-row>
        <!-- left align -->
        <b-col
          md="12"
          lg="12"
        >
          <b-card
            title=""
            class="mb-3"
          >
            <template>

              <!-- Table Container Card -->
              <b-card no-body>
                <template>
                  <div>
                    <b-row>
                      <b-col>
                        <h5 class="text-right filtre_label">
                          Filtre par date :
                        </h5>
                      </b-col>
                      <b-col md="3">
                        <label for="debut">Du :</label>
                        <b-input-group class="mb-1">
                          <b-form-input
                            id="debut"
                            v-model="date_debut"
                            type="text"
                            placeholder="YYYY-MM-DD"
                            autocomplete="off"
                            show-decade-nav
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="date_debut_dp"
                              show-decade-nav
                              button-only
                              right
                              locale="fr-FR"
                              :formatted="formatDate"
                              aria-controls="debut"
                              @context="onContext"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col
                        md="3"
                      >
                        <label for="fin">Au :</label>
                        <b-input-group class="mb-1">
                          <b-form-input
                            id="fin"
                            v-model="date_fin"
                            type="text"
                            placeholder="YYYY-MM-DD"
                            autocomplete="off"
                            show-decade-nav
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="date_fin_dp"
                              show-decade-nav
                              button-only
                              right
                              locale="fr-FR"
                              aria-controls="fin"
                              selected-variant="primary"
                              @context="onContext"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                      <b-col md="4">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="mr-1 btn_filter"
                          @click="filter"
                        >
                          Filtrer
                        </b-button>
                      </b-col>
                    </b-row>
                    <!-- input search -->
                    <div class="custom-search d-flex justify-content-end">
                      <b-form-group>
                        <div class="d-flex align-items-center">
                          <label class="mr-1">Recherche
                          </label>
                          <b-form-input
                            v-model="searchTerm"
                            placeholder="Recherche"
                            type="text"
                            class="d-inline-block"
                          />
                        </div>
                      </b-form-group>
                      <!-- primary -->
                      <b-dropdown
                        id="dropdown-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        text="Exporter"
                        variant="primary"
                        class="ml-2 btn_export"
                      >
                        <b-dropdown-item @click="exportCSV">
                          CSV
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportExcel">
                          MS-Excel
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <b-overlay
                      :show="showOverlay"
                      rounded="sm"
                      no-fade
                    >
                      <!-- table -->
                      <vue-good-table
                        id="list"
                        ref="myTable"
                        :columns="columns"
                        :rows="tickets"
                        :rtl="direction"
                        :search-options="{
                          enabled: true,
                          externalQuery: searchTerm }"
                        :pagination-options="{
                          enabled: true,
                          perPage:pageLength
                        }"
                      >
                        <template
                          slot="table-row"
                          slot-scope="props"
                        >

                          <!-- Column: Name -->
                          <div
                            v-if="props.column.field === 'fullName'"
                            class="text-nowrap"
                          >
                            <b-avatar
                              :src="props.row.avatar"
                              class="mx-1"
                            />
                            <span class="text-nowrap">{{ props.row.fullName }}</span>
                          </div>

                          <!-- Column: Status -->
                          <span v-else-if="props.column.field === 'status'">
                            <b-badge :variant="statusVariant(props.row.status)">
                              {{ props.row.status }}
                            </b-badge>
                          </span>

                          <!-- Column: Action -->
                          <span v-else-if="props.column.field === 'action'">
                            <span>
                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                              >
                                <template v-slot:button-content>
                                  <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25"
                                  />
                                </template>
                                <b-dropdown-item>
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="mr-50"
                                  />
                                  <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item>
                                  <feather-icon
                                    icon="TrashIcon"
                                    class="mr-50"
                                  />
                                  <span>Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </span>
                          </span>

                          <!-- Column: Common -->
                          <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                          </span>
                        </template>

                        <!-- pagination -->
                        <template
                          slot="pagination-bottom"
                          slot-scope="props"
                        >
                          <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                              <span class="text-nowrap">
                                Affichage de 1 à
                              </span>
                              <b-form-select
                                v-model="pageLength"
                                :options="['3','5','10']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                              />
                              <span class="text-nowrap "> sur {{ props.total }} élements </span>
                            </div>
                            <div>
                              <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                              >
                                <template #prev-text>
                                  <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                  />
                                </template>
                                <template #next-text>
                                  <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                  />
                                </template>
                              </b-pagination>
                            </div>
                          </div>
                        </template>
                      </vue-good-table>
                    </b-overlay>
                  </div>
                </template>
              </b-card>

            </template>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BFormInput,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
// import axios from 'axios'
import store from '@/store/index'
import { getTickets } from '@/api-services/caisse'
import { dateFormater, firstDayOfMonth } from '@/helpers/methodes'
import htmlToPaper from 'vue-html-to-paper'
import Papa from 'papaparse'
import XLSX from 'xlsx'
// eslint-disable-next-line import/no-cycle
export default {
  components: {
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  data() {
    return {
      date_debut: '',
      date_debut_dp: '',
      date_fin: '',
      date_fin_dp: '',
      formatted: '',
      selected: '',
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Ref Ticket',
          field: 'n_ticket',
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche',
          },
        },
        {
          label: 'Date de création',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche',
          },
        },
        {
          label: 'Caissier',
          field: 'user.caissier',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche',
          },
        },
        {
          label: 'Ref Patient',
          field: 'patient.id',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche',
          },
        },
        {
          label: 'Patient',
          field: 'patient.nom_complet',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche',
          },
        },
        {
          label: 'Tierce Payant',
          field: 'reste_a_payer',
          type: 'number',
        },
        {
          label: 'Total',
          field: 'montant_total',
          type: 'number',
        },
        {
          label: 'Montant Encaissé',
          field: 'montant_encaisse',
          type: 'number',
        },
        {
          label: 'Montant prise en charge',
          field: 'prise_en_charge',
          type: 'number',
        },
        {
          label: 'Etat',
          field: 'etat',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher un etat',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      tickets: [],
      showOverlay: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    date_debut_dp(newValue) {
      this.date_debut = dateFormater(newValue)
    },
    date_fin_dp(newValue) {
      this.date_fin = dateFormater(newValue)
    },
  },
  created() {
    this.date_debut = dateFormater(firstDayOfMonth())
    this.date_fin = dateFormater(new Date())
    // console.log(this.date_debut, this.date_fin)
    this.getTicketsList()
  },
  mounted() {
  },
  methods: {
    exportCSV() {
      const ticketsToExport = this.tickets.map(ticket => ({
        'Ref Ticket': ticket.n_ticket,
        date: ticket.date,
        Caissier: ticket.user.caissier,
        patient: ticket.patient.nom_complet,
        reste_a_payer: ticket.reste_a_payer,
        montant_total: ticket.montant_total,
        montant_encaisse: ticket.montant_encaisse,
        prise_en_charge: ticket.prise_en_charge,
        etat: ticket.etat,
      }))
      // Convertir les données en format CSV
      const csv = Papa.unparse(ticketsToExport)
      // Créer un élément de lien pour télécharger le fichier CSV
      const link = document.createElement('a')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`)
      link.setAttribute('download', `list_tickets_${this.date_debut}_${this.date_fin}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportExcel() {
      const ticketsToExport = this.tickets.map(ticket => ({
        'Ref Ticket': ticket.n_ticket,
        date: ticket.date,
        Caissier: ticket.user.caissier,
        patient: ticket.patient.nom_complet,
        reste_a_payer: ticket.reste_a_payer,
        montant_total: ticket.montant_total,
        montant_encaisse: ticket.montant_encaisse,
        prise_en_charge: ticket.prise_en_charge,
        etat: ticket.etat,
      }))
      const worksheet = XLSX.utils.json_to_sheet(ticketsToExport)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      XLSX.writeFile(workbook, `list_tickets_${this.date_debut}_${this.date_fin}.xlsx`)
    },
    print() {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          'https://unpkg.com/vue-good-table/dist/vue-good-table.css',
        ],
        allData: true,
      }
      this.$htmlToPaper('list', options)
    },
    filter() {
      this.getTicketsList()
    },
    formatDate(date) {
      return dateFormater(date)
    },
    getTicketsList() {
      this.showOverlay = true
      getTickets(this, {
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: 1,
      })
        .then(res => {
          this.tickets = res.data
          this.showOverlay = false
        })
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.seleted = ctx.selectedYMD
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
